import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BsLightningCharge } from 'react-icons/bs';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { IconTooltip, HiddenIcon } from '@/pages/Schedule/components';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import type { DecoratedInstance } from '@/pages/Schedule/types';
import {
  formatInstanceTime,
  getEntryItemStyles,
  isInstanceOnPrimaryFeed,
} from '@/pages/Schedule/utils/entry';
import { definitions as labelDefinitions } from '@/pages/Schedule/utils/labelDefinitions';
import { colors as themeColors } from '@/theme';
import {
  MotionBox,
  type MotionBoxProps,
  Box,
  Text,
  IconButton,
  Tooltip,
  Flex,
  type TextProps,
} from '@/ui';
import { useScheduleContext } from '../../contexts';

type Props = MotionBoxProps & {
  instance: DecoratedInstance;
  titleProps?: TextProps;
  onHideEntryClick?: () => void;
  onClick?: (event: MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const EntryItem = ({
  instance,
  titleProps,
  onClick,
  onHideEntryClick,
  ...props
}: Props) => {
  const { t } = useTranslation(['common', 'schedule']);
  const { scheduleId } = useScheduleContext();
  const { category, whoLabels, labels } = instance;
  const time = formatInstanceTime(instance);
  const { hasSchedulePermission } = useSchedulePermissions();
  const { value: hideItemsEnabled } = useFeatureFlag('hideItemsEnabled');
  const styles = getEntryItemStyles(instance);
  const canHideItems =
    hideItemsEnabled &&
    onHideEntryClick &&
    hasSchedulePermission('ENTRY_UPDATE');

  const entryButtons = (
    <Box alignItems="center" display="inline-flex" ml="auto">
      {canHideItems && (
        <Box
          _groupHover={{ visibility: 'visible', opacity: 1 }}
          mr="1"
          opacity="0"
          sx={{ '@media print': { display: 'none' } }}
          transition="opacity 0.2s ease"
          visibility="hidden"
        >
          <Tooltip
            label={
              instance.isHidden
                ? t('common:unhide_entry')
                : t('common:hide_entry')
            }
          >
            <IconButton
              icon={<HiddenIcon isHidden={instance.isHidden} />}
              size="xs"
              variant="ghost"
              aria-label={
                instance.isHidden
                  ? t('common:unhide_entry')
                  : t('common:hide_entry')
              }
              onClick={(event) => {
                event.stopPropagation();
                onHideEntryClick();
              }}
              {...styles.hideIcon}
            />
          </Tooltip>
        </Box>
      )}
      {instance.feed && !isInstanceOnPrimaryFeed(instance, scheduleId) && (
        <IconTooltip
          icon={<BsLightningCharge color={themeColors.customgray.dark} />}
          label={t('schedule:imported_from', {
            feed: instance.feed.title,
          })}
        />
      )}
    </Box>
  );

  const hasAnyLabels = whoLabels.length > 0 || labels.length > 0;
  const placeButtonsNextToTags = hasAnyLabels;
  const placeButtonsNextToTime =
    !placeButtonsNextToTags && !hasAnyLabels && !!time;
  const placeButtonsInOwnFeedRow =
    !placeButtonsNextToTime &&
    !placeButtonsNextToTags &&
    !category?.text &&
    !!instance.feed;
  const placeButtonsInOwnRow =
    !placeButtonsNextToTime &&
    !placeButtonsNextToTags &&
    !placeButtonsInOwnFeedRow;

  const containerStyles = onClick
    ? styles.container
    : {
        ...styles.container,
        _hover: undefined,
      };

  return (
    <MotionBox
      borderRadius="4"
      cursor={onClick ? 'pointer' : 'default'}
      data-entry-id={instance.id}
      data-group
      data-testid="entry-item"
      display="flex"
      flexDirection="column"
      pb="3"
      role="button"
      width="100%"
      onClick={onClick}
      {...containerStyles}
      {...props}
    >
      {category?.text && (
        <Box
          borderTopLeftRadius="4"
          borderTopRightRadius="4"
          px="3"
          py="2"
          {...styles.category}
        >
          {category.text}
        </Box>
      )}

      {/* Title and emoji */}
      <Flex flex="1" mt="3" px="3">
        <Text
          flex="1"
          fontSize={{ base: 14, lg: 15 }}
          fontWeight="medium"
          lineHeight={{ base: '18px', lg: '22px' }}
          overflowWrap="anywhere"
          sx={{ '@media print': { fontSize: 14 } }}
          {...titleProps}
        >
          {instance.emoji && (
            <Box as="span" fontSize="20px" mr="1.5" pos="relative" top="2px">
              {instance.emoji}
            </Box>
          )}
          {instance.title || t('common:untitled_entry')}
        </Text>
      </Flex>

      {time && (
        <Flex align="center" justify="flex-end" mt="2" px="3">
          <Text
            data-testid="entry-time"
            flex="1"
            fontSize="sm"
            sx={{ '@media print': { fontSize: 'xs' } }}
          >
            {time}
          </Text>

          {placeButtonsNextToTime && entryButtons}
        </Flex>
      )}

      {hasAnyLabels && (
        <Flex align="center" flex="1" gap="1" mt="3" px="3" wrap="wrap">
          {[...labels, ...whoLabels].map((label) => (
            <Flex key={label.id} {...styles.tag}>
              <Box>
                {whoLabels.includes(label)
                  ? labelDefinitions.WHO.iconSm
                  : labelDefinitions.DEFAULT.iconSm}
              </Box>
              <Box
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {label.text}
              </Box>
            </Flex>
          ))}
          {placeButtonsNextToTags && entryButtons}
        </Flex>
      )}

      {placeButtonsInOwnRow && (
        <Flex align="center" justify="flex-end" mt="3" pr="2">
          {entryButtons}
        </Flex>
      )}

      {placeButtonsInOwnFeedRow && (
        <Flex align="center" justify="flex-end" mt="3" px="3">
          {entryButtons}
        </Flex>
      )}
    </MotionBox>
  );
};
